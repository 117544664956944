import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import useFirestore from '../../hooks/useFirestore'
import { USER_COLLECTION } from '../../_constants/globals'
import { useTranslation } from 'react-i18next'
import { getSuperPowerBadge } from '../../_helpers/cardHelper'


const SuperPowerBox = ({ userId }) => {
  
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const { t } = useTranslation()
  
  const { profileType, gender, firstname } = useMemo(() => dbUsers?.find(u => u.id === userId) || {}, [dbUsers, userId])
  
  const badge = useMemo(() => getSuperPowerBadge(profileType), [profileType])
  
  return (
    <Box display='flex'>
      <Box component='img' src={badge} alt='super power' sx={{ height: '40px' }} />
      <Box>
        <Typography fontWeight={700}>{t(`cartography.${profileType}.title`)}</Typography>
        <Typography>{t(`cartography.${profileType}.${gender || 'man'}`).replace('FIRSTNAME', firstname)}</Typography>
      </Box>
    </Box>
  )
}

SuperPowerBox.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default SuperPowerBox
