import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router'
import { AVATAR, DEPUBLISHED, HOME, LOGIN, ONBOARDING, TEST } from '../../_constants/routes'
import { useDispatch } from 'react-redux'
import Loading from './Loading'
import { ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_MANAGER, TEAM_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { SELECT_USER_ID, SET_CONSULTANT_MODE } from '../../store/types'
import { useSearchParams } from 'react-router-dom'
import useFirestore from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const RestrictedArea = ({ children, unauthenticated, authenticated, onboarded, avatar, freemiumOrManager, testFinished, admin }) => {
  const profile = useAuth().getProfile()
  const location = useLocation()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const userHooks = useFirestore(USER_COLLECTION)
  const teamHooks = useFirestore(TEAM_COLLECTION)
  
  useEffect(() => {
    const callback = async () => {
      if (!profile.id) return
      const promises = []
      
      // if teamId is set in url params, select team
      const teamId = searchParams.get('teamId')
      if (teamId && profile.id) {
        console.info('Select team from URL', teamId)
        promises.push(userHooks.updateDoc(profile.id, { currentTeamSelectedRef: teamHooks.getDocRef(teamId) }))
      }
      
      // if uid is set in url params, select user in redux
      const uid = searchParams.get('uid')
      if (uid) {
        console.info('Select user from URL', uid)
        promises.push(dispatch({ type: SELECT_USER_ID, payload: uid }))
      }
      
      // if consultantMode is set in url params, set it in redux
      const consultantMode = searchParams.get('consultantMode')
      if (consultantMode) promises.push(dispatch({ type: SET_CONSULTANT_MODE, payload: true }))
      
      await Promise.all(promises)
      setSearchParams({})
    }
    callback()
  }, [profile.id, location.search, searchParams])
  
  if (!profile.isLoaded) return <Loading />
  
  else if (authenticated && profile.isEmpty) return <Navigate to={LOGIN} state={{ from: location }} replace />
  else if (profile.company?.status === 'depublished' && location.pathname !== DEPUBLISHED) return <Navigate to={DEPUBLISHED} state={{ from: location }} replace />
  else if (unauthenticated && profile.id) return <Navigate to={HOME} state={{ from: location }} replace />
  else if (testFinished && (!profile.testResults || !profile.email || !profile.firstname)) return <Navigate to={TEST} state={{ from: location }} replace />
  else if (profile.roles?.includes('consultant')) return children
  else if (onboarded && profile.onboardingStatus !== 'done') return <Navigate to={ONBOARDING} state={{ from: location }} replace />
  else if (avatar && !profile.photoUrl && !profile.photoUrlNotNow) return <Navigate to={AVATAR} state={{ from: location }} replace />
  else if (freemiumOrManager && !!profile.companyRef && profile.accessLevel < ACCESS_LEVEL_MANAGER) return <Navigate to={HOME} state={{ from: location }} replace />
  else if (admin && profile.accessLevel < ACCESS_LEVEL_ADMIN) return <Navigate to={HOME} state={{ from: location }} replace />
  
  else return children
}

RestrictedArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  unauthenticated: PropTypes.bool,
  authenticated: PropTypes.bool,
  onboarded: PropTypes.bool,
  avatar: PropTypes.bool,
  freemiumOrManager: PropTypes.bool,
  testFinished: PropTypes.bool,
  admin: PropTypes.bool,
}

export default RestrictedArea
