import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { POSITION, TEAM_PROFILE } from '../../_constants/cardTypes'
import Loading from '../_library/Loading'
import SumUp from './SumUp'
import Sources from '../cards/Sources'
import { useNavigate, useLocation } from 'react-router-dom'
import Quote from './Quote'
import ImageInCard from './ImageInCard'
import { renderToString } from 'react-dom/server'
import PersonalityGraph from '../_library/PersonalityGraph'
import { HOME, PRICING } from '../../_constants/routes'
import blurryGraphImg from '../../assets/DIVERS/blurry-graph.jpeg'
import CTAButton from '../_library/FirstLevelButton'
import CardHeader from './CardHeader'
import { Box, Typography } from '@mui/material'
import Page from '../_library/Page'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { USER_COLLECTION } from '../../_constants/globals'
import useCards from '../../hooks/useCards'
import { useTranslation } from 'react-i18next'
import StickyScrollBtn from '../_library/StickyScrollBtn'
import { useScroll } from 'framer-motion'


const CardPage = ({ type }) => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const users = userHooks.getDocs()
  const navigate = useNavigate()
  const location = useLocation()
  const pageContentRef = useRef(null)
  const selectedUserId = useSelector(state => state.data.selectedUserId)
  const { cards, readCard, drawNextCard } = useCards()
  const { t } = useTranslation()
  const { scrollYProgress } = useScroll({ container: pageContentRef })
  const consultantMode = useSelector(state => state.data.consultantMode)
  
  const [scrollProgress, setScrollProgress] = useState(0)
  
  const card = useMemo(() => {
    if (!cards) return null
    else return cards.find(c => c.type === type)
  }, [cards, type, location.pathname, profile.id])
  
  useEffect(() => {
    if (card) {
      console.log(`card id ${card.id}`)
      if (type !== TEAM_PROFILE) readCard(card.id)
      drawNextCard(card.id)
    }
  }, [type, card?.id])
  
  useEffect(() => {scrollYProgress.on('change', setScrollProgress)}, [scrollYProgress])
  
  if (!card) return <Loading />
  const { subtitle, title, intro, user, sumUp, sources, article } = card
  return (
    <Page withTopBar ref={pageContentRef}>
      <Box sx={{ pb: '140px' }}>
        <CardHeader title={title} subtitle={subtitle} type={type} avatarUrl={user.photoUrl} />
        <Box sx={{
          mx: { xs: 1, sm: 'auto' },
          pl: { xs: 0, sm: '11%' },
          textAlign: 'left',
          width: { xs: 'inherit', md: '60vw' },
          maxWidth: { xs: '100%', sm: '700px' },
          position: 'relative',
          color: 'black',
          fontSize: '.9rem',
          lineHeight: '2rem',
        }}>
          <Box>
            {intro && <Typography fontSize='1.3rem' fontWeight={600} lineHeight='2.5rem' pt={{ xs: 0, md: '2rem' }}>{intro}</Typography>}
            {type === POSITION && profile.companyRef && !selectedUserId &&
              <PersonalityGraph users={[profile]} displayHeart displayWeb />
            }
            {type === POSITION && selectedUserId && users &&
              <PersonalityGraph users={users.filter(u => u.id === selectedUserId)} displayHeart displayWeb />
            }
          </Box>
          <Box sx={{
            my: '0.9rem',
            lineHeight: '2rem',
            fontSize: '1.15em',
            '& h3': {
              color: theme => theme.palette.tertiary?.dark,
              marginBottom: '0.4rem',
              fontSize: '1.4rem',
              fontWeight: 800,
              marginTop: '2rem',
            },
            '& ul': {
              listStylePosition: 'inside',
              pl: 0,
            },
            '& li': {
              fontSize: '1rem',
              fontWeight: 700,
            },
          }}>
            {article && parse(article
              .replace('FIRSTNAME', user?.firstname || 'firstname')
              .replace('BIDEN_IMAGE', renderToString(<ImageInCard preset='bidenImage' />))
              .replace('NONVIOLENCE_GANDHI', renderToString(<Quote preset='nonViolenceGandhi' />))
              .replace('GATHERING_FORD', renderToString(<Quote preset='GatheringFord' />)),
            )}
          </Box>
          {sumUp && <SumUp list={sumUp} />}
          {sources && <Sources sources={sources} />}
          {type === POSITION && !profile.companyRef && <Box sx={{ position: 'relative' }}>
            <Box component='img' src={blurryGraphImg} alt='blurry graph' sx={{ width: '100%' }} />
            <CTAButton onClick={() => navigate(PRICING)} sx={{
              bgcolor: 'primary.light',
              height: '44px',
              width: '284px',
              position: 'absolute',
              top: 'calc(50% - 30px)',
              left: 'calc(50% - 142px)',
              fontSize: '1rem',
            }}>{t('dashboard.unlock-full')}</CTAButton>
          </Box>}
          <StickyScrollBtn onClick={() => navigate(HOME)} pageScrollPercent={scrollProgress} disabled={consultantMode}>{t('dashboard.next-card')}</StickyScrollBtn>
        </Box>
      </Box>
    </Page>
  )
}

CardPage.propTypes = {
  type: PropTypes.string.isRequired,
}

export default CardPage
