import React, { useEffect, useMemo, useRef, useState } from 'react'
import parse from 'html-react-parser'
import Typography from '@mui/material/Typography'
import { HOME } from '../../_constants/routes'
import PersonalityGraph from '../_library/PersonalityGraph'
import { useLocation, useNavigate } from 'react-router-dom'
import { USER_COLLECTION } from '../../_constants/globals'
import StickyScrollBtn from '../_library/StickyScrollBtn'
import CardHeader from '../cards/CardHeader'
import { TALENT_EXT } from '../../_constants/cardTypes'
import { Box } from '@mui/material'
import Page from '../_library/Page'
import { useScroll } from 'framer-motion'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { useTranslation } from 'react-i18next'
import SuperPowerBox from './SuperPowerBox'
import useCards from '../../hooks/useCards'
import Loading from '../_library/Loading'
import StarsUnderCard from '../cards/StarsUnderCard'
import { renderToString } from 'react-dom/server'
import RatingComponent from '../cards/RatingComponent'
import FamousAvatars from '../cards/FamousAvatars'
import Sources from '../cards/Sources'
import SumUp from '../cards/SumUp'
import Balloon, { BACKDROP_TYPE } from '../cards/Balloon'
import Quote from '../cards/Quote'
import ImageInCard from '../cards/ImageInCard'


const TalentExtPage = () => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const users = userHooks.getDocs()
  const navigate = useNavigate()
  const location = useLocation()
  const pageContentRef = useRef(null)
  const { scrollYProgress } = useScroll({ container: pageContentRef })
  const { cards, readCard, drawNextCard } = useCards()
  const { t } = useTranslation()
  
  const [showBalloonExtCard, setShowBalloonExtCard] = useState(true)
  const [scrollProgress, setScrollProgress] = useState(0)
  
  const teamMembers = useMemo(() => users?.filter(u => u.teamRefs?.some(t => t.id === profile.currentTeamSelectedRef?.id) && u.id !== profile.id), [users, profile])
  
  const card = useMemo(() => {
    if (!cards) return null
    else return cards.find(c => c.type === TALENT_EXT && c.userRef.id === location.pathname.split('/').pop())
  }, [cards, location.pathname])
  
  useEffect(() => {
    if (card) {
      console.log(`talent ext card id ${card.id}`)
      readCard(card.id)
      drawNextCard(card.id)
    }
  }, [card?.id])
  
  useEffect(() => {scrollYProgress.on('change', setScrollProgress)}, [scrollYProgress])
  
  if (!card) return <Loading />
  const { subtitle, title, intro, user, userRef, sumUp, sources, article } = card
  return (
    <Page withTopBar ref={pageContentRef}>
      <Box sx={{ pb: '140px' }}>
        <CardHeader title={title} subtitle={subtitle} type={TALENT_EXT} avatarUrl={user.photoUrl} />
        <Box sx={{
          mx: { xs: 1, sm: 'auto' },
          pl: { xs: 0, sm: '11%' },
          textAlign: 'left',
          width: { xs: 'inherit', md: '60vw' },
          maxWidth: { xs: '100%', sm: '700px' },
          position: 'relative',
          color: 'black',
          fontSize: '.9rem',
          lineHeight: '2rem',
        }}>
          <Box sx={{ display: { xs: 'flex', sm: 'block' }, flexDirection: { xs: 'column-reverse', sm: 'initial' } }}>
            <Box sx={{
              pr: { xs: 'inherit', md: '45px' },
              maxWidth: { xs: 'inherit', md: '300px' },
              position: { xs: 'inherit', md: 'absolute' },
              left: { xs: 'inherit', md: '-14vw' },
              top: { xs: 'inherit', md: '260px' },
            }}>
              {userRef.id !== profile.id &&
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <StarsUnderCard firstname={card.user.firstname} rating={card.rating} comment={card.comment} />
                </Box>
              }
            </Box>
            <Box sx={{
              position: { xs: 'static', md: 'absolute' },
              right: '-250px',
              top: '-140px',
              width: 250,
            }}>
              <SuperPowerBox user={user} />
            </Box>
            <div>
              {intro && <Typography fontSize='1.3rem' fontWeight={600} lineHeight='2.5rem' pt={{ xs: 0, md: '2rem' }}>{intro}</Typography>}
              {profile.companyRef &&
                <PersonalityGraph users={[userRef.id === profile.id ? profile : teamMembers?.find(u => u.id === userRef.id)]} displayHeart displayWeb />
              }
            </div>
          </Box>
          <Box sx={{
            my: '0.9rem',
            lineHeight: '2rem',
            fontSize: '1.15em',
            '& h3': {
              color: theme => theme.palette.tertiary?.dark,
              marginBottom: '0.4rem',
              fontSize: '1.4rem',
              fontWeight: 800,
              marginTop: '2rem',
            },
            '& ul': {
              listStylePosition: 'inside',
              pl: 0,
            },
            '& li': {
              fontSize: '1rem',
              fontWeight: 700,
            },
          }}>
            {article && parse(article
              .replace('FIRSTNAME', user?.firstname || 'firstname')
              .replace('BIDEN_IMAGE', renderToString(<ImageInCard preset='bidenImage' />))
              .replace('NONVIOLENCE_GANDHI', renderToString(<Quote preset='nonViolenceGandhi' />))
              .replace('GATHERING_FORD', renderToString(<Quote preset='GatheringFord' />)),
            )}
          </Box>
          {sumUp && <SumUp list={sumUp} />}
          {sources && <Sources sources={sources} />}
          <FamousAvatars profileType={user.profileType} />
          {userRef.id === profile.id
            ? card && <RatingComponent card={card} next={() => navigate(HOME)} />
            : <StickyScrollBtn onClick={() => navigate(HOME)} pageScrollPercent={scrollProgress} disabled={profile.companyRef?.id !== card.companyRef?.id}>
              {t('dashboard.next-card')}
            </StickyScrollBtn>
          }
          {userRef.id === profile.id && !card.rating && showBalloonExtCard && <Balloon
            preset='goldTalentSummary'
            onCTA={() => setShowBalloonExtCard(false)}
            backdropType={BACKDROP_TYPE.transparentGreen}
          />}
        </Box>
      </Box>
    </Page>
  )
}

export default TalentExtPage
