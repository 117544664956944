import React from 'react'
import Page from './_library/Page'
import Balloon, { BACKDROP_TYPE } from './cards/Balloon'


const DepublishedPage = () => (
  <Page withTopBar sx={{ bgcolor: 'rgb(61, 173, 173, 1)' }}>
    <Balloon
      preset='depublished'
      onCTA={() => {window.SibConversations('expandWidget')}}
      backdropType={BACKDROP_TYPE.noBackdrop}
      style={{ zIndex: '1' }}
      styleTitle={{ pt: { sm: '50px' }, fontSize: '2.15rem' }}
    />
  </Page>
)

export default DepublishedPage
