import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getSuperPowerBadge } from '../../_helpers/cardHelper'


const SuperPowerBox = ({ user: { profileType, gender, firstname } }) => {
  
  const { t } = useTranslation()
  
  const badge = useMemo(() => getSuperPowerBadge(profileType), [profileType])
  
  return (
    <Box>
      <Box component='img' src={badge} alt='super power' sx={{ height: '230px' }} />
      <Typography fontWeight={700}>{t(`cartography.${profileType}.title`)}</Typography>
      <Typography>{t(`cartography.${profileType}.${gender || 'man'}`).replace('FIRSTNAME', firstname)}</Typography>
    </Box>
  )
}

SuperPowerBox.propTypes = {
  user: PropTypes.object.isRequired,
}

export default SuperPowerBox
