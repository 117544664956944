import React from 'react'
import CardPage from './CardPage'
import { DEMO_TEAM, ONE_TO_ONE_BUILDER, TALENT_EXT, TEAM_PROFILE } from '../../_constants/cardTypes'
import TeamCardPage from '../cartography/CartographyPage'
import { TEAMS } from '../../_constants/routes'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import OneToOnePage from '../one-to-one/OneToOnePage'
import PremiumAdCard from '../_library/PremiumAdCard'
import { Box, Link, useMediaQuery, useTheme } from '@mui/material'
import TalentExtPage from '../talentExt/TalentExtPage'


const CardRouter = () => {
  
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  
  return (
    <Routes>
      <Route path={ONE_TO_ONE_BUILDER} element={<OneToOnePage />} />
      <Route path={TEAM_PROFILE} element={<TeamCardPage />} />
      <Route path={DEMO_TEAM} element={
        <>
          <TeamCardPage demo />
          <Box sx={{
            backgroundColor: 'secondary.main',
            position: 'fixed',
            p: { xs: 4, md: '2rem 80px' },
            color: 'grey.100',
            width: '100%',
            bottom: 0,
            zIndex: 10,
            fontWeight: 600,
          }}
          >
            <span>
              Ceci est un exemple - Pour voir ce que cela donnerait en vrai&nbsp;
              <Link onClick={() => navigate(TEAMS)} sx={{ color: 'grey.100' }}>
                Invitez ici vos coéquipiers
              </Link>
            </span>
            {isMdUp && <PremiumAdCard />}
          </Box>
        </>
      } />
      <Route path={TALENT_EXT + '/*'} element={<TalentExtPage />} />
      <Route path='*' element={<CardPage type={location.pathname.split('/').pop()} />} />
    </Routes>
  )
}

export default CardRouter
