import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Avatar from '../_library/AvatarGraph'
import parse from 'html-react-parser'
import photoIcon from '../../assets/DIVERS/avatar_team_member.svg'
import PersonalityGraph from '../_library/PersonalityGraph'
import demoTeam from './demo-team.json'
import { ChevronLeft } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { SUM_UP } from '../../_constants/cardTypes'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { CARD_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useTranslation } from 'react-i18next'
import SuperPowerBox from './SuperPowerBox'


const TeamMateProfile = ({ userId, onClick, demo }) => {
  
  const profile = useAuth().getProfile()
  const cardHooks = useFirestore(CARD_COLLECTION)
  const dbCards = cardHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const { t } = useTranslation()
  
  const sumUpCard = useMemo(() => dbCards?.find(c => c.type === SUM_UP && c.userRef.id === userId), [dbCards, userId])
  const teamMembers = useMemo(() => dbUsers?.filter(u => u.teamRefs?.some(t => t.id === profile.currentTeamSelectedRef?.id) && u.id !== profile.id), [dbUsers, profile])
  
  const card = useMemo(() => demo && userId !== profile.id
    ? demoTeam.sumUpCards.find(c => c.id === userId)
    : sumUpCard, [userId, demo, sumUpCard, profile.id])
  
  if (!card) return null
  return (
    <Box onClick={onClick} sx={{ position: 'relative' }}>
      <Button onClick={onClick} sx={{
        position: 'absolute',
        right: '50px',
        textTransform: 'none',
        color: 'primary.light',
        zIndex: 2,
      }}>
        <ChevronLeft />&nbsp;{t('common.team')}
      </Button>
      <Avatar
        photoUrl={card.user?.photoUrl || photoIcon}
        sx={{
          right: 0,
          height: 'initial',
          minWidth: 'initial',
        }}
      />
      <PersonalityGraph
        users={demo && userId !== profile.id
          ? [demoTeam.members.find(c => c.id === userId)]
          : userId === profile.id
            ? [profile]
            : teamMembers.filter(u => u.id === userId)
        }
        displayWeb
        displayHeart
      />
      <Box sx={{
        position: { xs: 'static', md: 'absolute' },
        right: '-250px',
        top: 0,
        width: 250,
      }}>
        <SuperPowerBox userId={userId} />
      </Box>
      {parse(card.article)}
    </Box>
  )
}

TeamMateProfile.propTypes = {
  userId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  demo: PropTypes.bool,
}

export default TeamMateProfile
