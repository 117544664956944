import React from 'react'
import Page from '../_library/Page'


export const CatalogPage = () => (
  <Page withTopBar>
    <iframe src='https://boussole.io/rituels-de-feux-de-camp-iframe/' width='100%' height='100%' style={{ border: 'none' }} />
  </Page>
)

export default CatalogPage
